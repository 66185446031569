import { Col, Form, Select, Row, message, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import FormItem from '../form/FormItem';
import Input from '../../UI/inputs/Input';
import SelectInput from '../../UI/inputs/Select';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import Button from '../../UI/buttons/Button';
import { useAppSelector } from '../../store/hooks';
import { CaretDownOutlined } from '@ant-design/icons';
import { inviteOwnSupplier } from '../../api/recruiter';
import { useTranslation } from 'react-i18next';
import { ClientFeeTemplate } from '../../types';
import { getCompanyFeeTemplates } from '../../api/company';
import { RcFile } from 'antd/lib/upload';
import {
  ActionLink,
  ActionWrapper,
  DeleteFileIcon,
  FileName,
  FileUploader,
  StyledUploader,
  UploadAction,
} from '../recruitment_management/RecruiterEditAgreement';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import useHirePortTheme from '../../hooks/useHirePortTheme';
import Tab from '../../UI/Tab';
import { useGetEmploymentTypeNameById } from '../../hooks/useEmploymentTypes';
import { PAYMENT_TERM_TYPES } from '../client/MasterContractForm';
import Textarea from '../../UI/inputs/Textarea';
import useCurrency from '../../hooks/useCurrency';
import CurrencySelect from '../form/shared/currencies/CurrencySelect';
import TransactionFeeFormItem from '../form/shared/TransactionFeeFormItem';

const { TabPane } = Tabs;

export const FEE_TYPES = [
  {
    fee_name: 'Fixed',
    fee_value: 'fixed',
  },
  {
    fee_name: 'Percentage',
    fee_value: 'percentage',
  },
  {
    fee_name: 'Custom',
    fee_value: 'custom',
  },
];

interface RecruiterManagementBoardProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function AddCompanyRecruiterForm({
  isVisible,
  setIsVisible,
}: RecruiterManagementBoardProps) {
  const [t] = useTranslation();
  const defaultCurrency = useCurrency()
  const [currency, setCurrency] = useState(defaultCurrency);
  const theme = useHirePortTheme();
  const [form] = Form.useForm();
  const { user } = useAppSelector((state) => state.user);
  const extendedRecruiterAgreementModuleEnabled =
    user?.company?.extended_recruiter_agreement_enabled;
  const companyWideFeeSettingsEnabled =
    user?.company?.company_wide_fee_settings_enabled;
  const transactionFeeEnabled =
    user?.company?.transaction_fee_enabled;

  const [companyFeeTemplates, setCompanyFeeTemplates] = useState<
    ClientFeeTemplate[]
  >([]);
  const [file, setFile] = useState<string | FormData | undefined>('');
  const [newFileUploading, setNewFileUploading] = useState(false);
  const [
    showExtendedRecruiterAgreementFields,
    setShowExtendedRecruiterAgreementFields,
  ] = useState(false);
  const getEmploymentTypeNameById = useGetEmploymentTypeNameById();

  const onSub = (values: any) => {
    const data = new FormData();

    data.append('title', '');
    data.append('phone', '');
    data.append('name', values.name);
    data.append('email', values.email);
    data.append('recruiter_type', 'freelance');
    data.append('city', user?.company?.city?.id ?? '');
    // !TODO: invited_by can be removed as should now be made redundant by inviting_company_id
    data.append('invited_by', user?.company?.name ?? '');
    data.append('inviting_company_id', user?.company?.id ?? '');
    data.append('is_invited', 'true');
    data.append('compensation_type', values.compensation_type ?? '');
    data.append(
      'compensation_type_tooltip',
      values.compensation_type_tooltip ?? '',
    );
    data.append(
      'guarantee_period',
      String(values.guarantee_period || ''),
    );
    data.append(
      'payment_terms',
      String(values.payment_terms || ''),
    );
    data.append(
      'restitution',
      String(values.restitution || ''),
    );
    data.append(
      'restitution_payment_terms',
      String(values.restitution_payment_terms || ''),
    );
    data.append(
      'replacement_time',
      String(values.replacement_time || ''),
    );
    data.append(
      'exclusivity_for_replacement',
      values.exclusivity_for_replacement === true ||
        values.exclusivity_for_replacement === false
        ? String(values.exclusivity_for_replacement)
        : '',
    );
    data.append(
      'et_details_freelance',
      String(values.et_details_freelance || ''),
    );
    data.append(
      'et_details_ctp',
      String(values.et_details_ctp || ''),
    );
    data.append(
      'et_gda_ctp',
      String(values.et_gda_ctp || ''),
    );
    data.append(
      'et_cao_ctp',
      String(values.et_cao_ctp || ''),
    );
    data.append(
      'transaction_fee_to_company_percent',
      values.transaction_fee_to_company_percent || '0',
    );
    data.append(
      'fee_currency',
      String(values.fee_currency || ''),
    );
    if (values.contract?.file?.originFileObj) {
      data.append('contract', values.contract.file.originFileObj);
    } else if (file) {
      data.append('contract_url', file as string);
    } else if (!file) {
      data.append('contract', '');
    }

    inviteOwnSupplier(data)
      .then((_) => {
        message.success('Invite sent');
      })
      .catch((e: any) => {
        let err = e.response.data;
        if (
          err.error_code === 'data_invalid' &&
          err.message === 'You have already invited this supplier'
        ) {
          message.error('You’ve already invited this supplier');
          return;
        }
        message.error('Invitation failed, please try again later');
      });
  };

  const onFeeTemplateChange = (id: string) => {
    const feeTemplate = companyFeeTemplates.find(
      (template) => template.id === id,
    );
    if (feeTemplate?.id) {
      form.setFieldsValue({
        ...feeTemplate,
        name: form.getFieldValue('name'),
        exclusivity_for_replacement:
          feeTemplate.exclusivity_for_replacement === true
            ? 'Yes'
            : feeTemplate.exclusivity_for_replacement === false
              ? 'No'
              : '',
      });
      setFile(feeTemplate.contract);
    }
  };
  const handleUploadFile = (file: RcFile): boolean => {
    setFile(file.name);
    setNewFileUploading(true);
    return true;
  };

  const handleFileDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setFile('');
    form.setFieldsValue({ contract: null });
  };

  useEffect(() => {
    if (companyWideFeeSettingsEnabled) {
      getCompanyFeeTemplates().then((res: any) => {
        setCompanyFeeTemplates(res.data ?? []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      scrollToFirstError={true}
      style={{
        display: isVisible ? 'block' : 'none',
        backgroundColor: theme.lozengeColor,
        padding: '2em',
        paddingBottom: '0',
        borderRadius: '10px',
        marginBottom: '1em',
      }}
      form={form}
      onFinish={onSub}
      labelCol={{ span: 24 }}
      layout='vertical'
    >
      <Row gutter={10}>
        <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 12 }}>
          <FormItem
            name="email"
            label="EMAIL"
            rules={[
              { required: true, message: 'Please input your email!' },
              {
                type: 'email',
                message: 'Please enter a valid email address!',
              },
            ]}
          >
            <Input
              placeholder="Email"
              style={{ height: '40px' }}
              size="middle"
            />
          </FormItem>
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 12 }}>
          <FormItem
            name="name"
            label="FULL NAME"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input
              placeholder="Full Name"
              style={{ height: '40px' }}
              size="middle"
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={10}>
        {companyWideFeeSettingsEnabled ? (
          <>
            <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
              <FormItem label={t('FEE_TEMPLATE')}>
                <SelectInput
                  placeholder={t('FEE_TEMPLATE')}
                  size="middle"
                  onChange={onFeeTemplateChange}
                  showSearch
                  optionFilterProp="children"
                  filterOption={true}
                  dropdownMatchSelectWidth={false}
                  className="custom-select"
                  suffixIcon={<ArrowDownIcon />}
                >
                  {companyFeeTemplates.map((feeTemplate) => (
                    <Select.Option value={feeTemplate.id} key={feeTemplate.id}>
                      {feeTemplate.name}
                    </Select.Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}></Col>
          </>
        ) : (
          <>
            <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
              <FormItem
                name="compensation_type"
                label="FEE STRUCTURE"
                rules={[
                  { required: true, message: 'Please select a fee type!' },
                ]}
              >
                <SelectInput
                  placeholder="Fee Structure"
                  size="middle"
                  showSearch
                  optionFilterProp="children"
                  filterOption={true}
                  dropdownMatchSelectWidth={false}
                  className="custom-select"
                  suffixIcon={<ArrowDownIcon />}
                >
                  {FEE_TYPES.map((fee, index) => (
                    <Select.Option value={fee.fee_value} key={index}>
                      {fee.fee_name}
                    </Select.Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
            <Col
              xs={{ span: 12 }}
              md={{ span: 8 }}
              xl={{ span: extendedRecruiterAgreementModuleEnabled ? 6 : 18 }}
            >
              <FormItem
                name="compensation_type_tooltip"
                label={t('FEE_DETAILS_FORM_LABEL')}
                rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
              >
                <Input
                  type="number"
                  placeholder={t('FEE_DETAILS')}
                  maxLength={70}
                  style={{ height: '40px' }}
                  size="middle"
                />
              </FormItem>
            </Col>
          </>
        )}
      </Row>
      <FormItem
        hidden={!showExtendedRecruiterAgreementFields}
      >
        <Row>
          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
            <FormItem
              label={t('FEE_CURRENCY_FIELD_LABEL')}
              name="fee_currency"
              initialValue={currency.value}
            >
              <CurrencySelect
                size="large"
                onChange={(value) => {
                  setCurrency(currency.changeCurrency(value));
                }}
              />
            </FormItem>
          </Col>
        </Row>
        {transactionFeeEnabled && (
          <TransactionFeeFormItem form={form} name="transaction_fee_to_company_percent" />
        )}
        <Tab defaultActiveKey="1" className="mt-5">
          <TabPane tab={t('FEE_ET_TYPE', { employmentType: getEmploymentTypeNameById('permanent') })} key="1">
            <Row gutter={10}>
              {companyWideFeeSettingsEnabled && (
                <>
                  <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                    <FormItem
                      name="compensation_type"
                      label="FEE STRUCTURE"
                      rules={[
                        {
                          required: true,
                          message: 'Please select a fee type!',
                        },
                      ]}
                    >
                      <SelectInput
                        placeholder="Fee Structure"
                        size="middle"
                        showSearch
                        optionFilterProp="children"
                        filterOption={true}
                        dropdownMatchSelectWidth={false}
                        className="custom-select"
                        suffixIcon={<ArrowDownIcon />}
                      >
                        {FEE_TYPES.map((fee, index) => (
                          <Select.Option value={fee.fee_value} key={index}>
                            {fee.fee_name}
                          </Select.Option>
                        ))}
                      </SelectInput>
                    </FormItem>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    md={{ span: 8 }}
                    xl={{
                      span: extendedRecruiterAgreementModuleEnabled ? 6 : 18,
                    }}
                  >
                    <FormItem
                      name="compensation_type_tooltip"
                      label={t('FEE_DETAILS_FORM_LABEL')}
                      rules={[
                        {
                          required: true,
                          message: t('REQUIRED_FIELD'),
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder={t('FEE_DETAILS')}
                        maxLength={70}
                        style={{ height: '40px' }}
                        size="middle"
                      />
                    </FormItem>
                  </Col>
                </>
              )}
            </Row>
            <Row gutter={10}>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                <FormItem
                  name="restitution"
                  label={t('COMPANY_AGREEMENT_RESTITUTION')}
                >
                  <Input
                    type="number"
                    placeholder={t('COMPANY_AGREEMENT_RESTITUTION_PLACEHOLDER')}
                    maxLength={70}
                    style={{ height: '40px' }}
                    size="middle"
                  />
                </FormItem>
              </Col>

              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                <FormItem
                  name="replacement_time"
                  label={t('COMPANY_AGREEMENT_REPLACEMENT_TIME')}
                >
                  <Input
                    type="number"
                    placeholder={t(
                      'COMPANY_AGREEMENT_REPLACEMENT_TIME_PLACEHOLDER',
                    )}
                    maxLength={70}
                    style={{ height: '40px' }}
                    size="middle"
                  />
                </FormItem>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                <FormItem
                  name="guarantee_period"
                  label={t('COMPANY_AGREEMENT_GUARANTEE_PERIOD')}
                >
                  <Input
                    type="number"
                    placeholder={t('GUARANTEE_PERIOD_PLACEHOLDER')}
                    maxLength={70}
                    style={{ height: '40px' }}
                    size="middle"
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                <FormItem
                  name="payment_terms"
                  label={t('COMPANY_AGREEMENT_PAYMENT_TERMS')}
                >
                  <SelectInput
                    size="middle"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {PAYMENT_TERM_TYPES.map((payment_term, index) => (
                      <Select.Option value={payment_term.value} key={index}>
                        {payment_term.name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                <FormItem
                  name="restitution_payment_terms"
                  label={t('COMPANY_AGREEMENT_RESTITUTION_PAYMENT_TERMS')}
                >
                  <SelectInput
                    size="middle"
                    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                  >
                    {PAYMENT_TERM_TYPES.map((payment_term, index) => (
                      <Select.Option value={payment_term.value} key={index}>
                        {payment_term.name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>

              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                <FormItem
                  name="exclusivity_for_replacement"
                  label={t('COMPANY_AGREEMENT_EXCLUSIVITY_FOR_REPLACEMENT')}
                >
                  <SelectInput
                    allowClear
                    placeholder={t(
                      'COMPANY_AGREEMENT_EXCLUSIVITY_FOR_REPLACEMENT_PLACEHOLDER',
                    )}
                    size="middle"
                    showSearch
                    optionFilterProp="children"
                    filterOption={true}
                    dropdownMatchSelectWidth={false}
                    className="custom-select"
                    suffixIcon={<ArrowDownIcon />}
                  >
                    {['Yes', 'No'].map((val, index) => (
                      <Select.Option value={val} key={index}>
                        {val}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={t('FEE_ET_TYPE', { employmentType: getEmploymentTypeNameById('freelance') })} key="2">
            <Row gutter={10}>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                <FormItem
                  name="et_details_freelance"
                  label={t('COMPANY_AGREEMENT_ET_DETAILS', { employmentType: getEmploymentTypeNameById("freelance") })}
                >
                  <Input
                    type="text"
                    maxLength={70}
                    style={{ height: '40px' }}
                    size="middle"
                  />
                </FormItem>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={t('FEE_ET_TYPE', { employmentType: getEmploymentTypeNameById('ctp') })} key="3">
            <Row gutter={10}>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                <FormItem
                  name="et_details_ctp"
                  label={t('COMPANY_AGREEMENT_ET_DETAILS', { employmentType: getEmploymentTypeNameById("ctp") })}
                >
                  <Input
                    type="text"
                    maxLength={70}
                    style={{ height: '40px' }}
                    size="middle"
                  />
                </FormItem>
              </Col>
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                <FormItem
                  name="et_cao_ctp"
                  label={t('COMPANY_AGREEMENT_ET_CAO', { employmentType: getEmploymentTypeNameById("ctp") })}
                >
                  <Input
                    type="text"
                    maxLength={70}
                    style={{ height: '40px' }}
                    size="middle"
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col xs={{ span: 24 }} md={{ span: 16 }} xl={{ span: 12 }}>
                <FormItem
                  name="et_gda_ctp"
                  label={t('COMPANY_AGREEMENT_ET_GDA', { employmentType: getEmploymentTypeNameById("ctp") })}
                >
                  <Textarea
                    autoSize={{ minRows: 3 }}
                  />
                </FormItem>
              </Col>
            </Row>
          </TabPane>
        </Tab>
        <Row gutter={10} style={{ marginBottom: '1.5rem' }}>
          <>
            {file && !newFileUploading ? (
              <div style={{ display: 'flex' }}>
                <ActionWrapper>
                  <ActionLink
                    href={file as string}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DownloadIcon />
                    {t('COMPANY_AGREEMENT_DOWNLOAD_CONTRACT')}
                  </ActionLink>
                </ActionWrapper>
                <UploadAction>
                  <button
                    style={{
                      border: 'none',
                      display: 'flex',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <DeleteFileIcon onClick={handleFileDelete} />
                  </button>
                </UploadAction>
              </div>
            ) : (
              <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 6 }}>
                <FormItem
                  label={t('COMPANY_AGREEMENT_CONTRACT')}
                  name="contract"
                >
                  <StyledUploader
                    name="file"
                    showUploadList={false}
                    beforeUpload={handleUploadFile}
                    customRequest={() => void 0}
                  >
                    <FileUploader>
                      <FileName>{file}</FileName>
                      <UploadAction>
                        <Button type={'default'} size="small">
                          {t('CHOOSE_FILE')}
                        </Button>

                        {file && (
                          <DeleteFileIcon onClick={handleFileDelete} />
                        )}
                      </UploadAction>
                    </FileUploader>
                  </StyledUploader>
                </FormItem>
              </Col>
            )}
          </>
        </Row>
      </FormItem>
      <Row
        gutter={10}
        justify={
          extendedRecruiterAgreementModuleEnabled ? 'space-between' : 'end'
        }
      >
        {extendedRecruiterAgreementModuleEnabled && (
          <Col>
            <FormItem>
              <Button
                style={{
                  color: '#627d95',
                  borderColor: '#627d95',
                  height: '40px',
                }}
                size="middle"
                onClick={() =>
                  setShowExtendedRecruiterAgreementFields(
                    !showExtendedRecruiterAgreementFields,
                  )
                }
              >
                {showExtendedRecruiterAgreementFields
                  ? t('RECRUITER_MANAGEMENT_HIDE_EXTENDED_FIELDS')
                  : t('RECRUITER_MANAGEMENT_SHOW_EXTENDED_FIELDS')}
              </Button>
            </FormItem>
          </Col>
        )}
        <Row gutter={10}>
          <Col>
            <FormItem>
              <Button
                style={{
                  color: '#627d95',
                  borderColor: '#627d95',
                  height: '40px',
                }}
                size="middle"
                onClick={() => {
                  setIsVisible(false);
                }}
              >
                {t('CANCEL_TEXT')}
              </Button>
            </FormItem>
          </Col>
          <Col>
            <FormItem>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => form.submit()}
                style={{ height: '40px' }}
                size="middle"
              >
                {t('SEND_INVITE')}
              </Button>
            </FormItem>
          </Col>
        </Row>
      </Row>
    </Form>
  );
}
